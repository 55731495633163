@import url("https://fonts.googleapis.com/css2?family=f1:wght@500&family=Roboto:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200&family=Ubuntu:ital,wght@1,500;1,400&display=swap");

$primary-font: "Arial", sans-serif;
$secondary-font: "Helvetica", sans-serif;

$roboto-font: "Roboto", sans-serif;

$ubuntu-font: "Fira Sans", sans-serif;
// Add more font-family declarations as needed
