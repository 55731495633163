@use "../commonStyles/styles" as globalStyles;
@use "../commonStyles/fonts" as globalFont;

.logo-inner {
  height: 25px;
  margin-right: 4rem;
  padding: 5% 0% 5% 0%;
}

.linkStyle {
  text-decoration: none; /* Remove the underline */
  color: globalStyles.$clr-black;
  text-transform: none;
  font-family: globalFont.$ubuntu-font;
}
.links-container {
  display: flex; /* Set the display to flex */
  width: 100%;
}

.linkStyle:hover,
.linkStyle:focus,
.linkStyle.active {
  text-decoration: underline;
}

.icon-color {
  color: globalStyles.$clr-black;
}
