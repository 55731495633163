@use "../../commonStyles/themes_1" as globalStyles;

.breadcrumb-link {
  text-decoration: none;
  font-size: inherit;
  display: inline-flex;
  align-items: center;
  gap: 2px;
  color: globalStyles.$clr-black; /* Default link color */
}

.breadcrumb-link:visited {
  color: globalStyles.$clr-purple; /* Visited link color */
}
