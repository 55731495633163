.s3-header{
    display: flex;
    justify-content: space-between;
    padding: 1% 1% 1% 0%;
}

.s3-subheader{
    padding: 1% 0% 1% 0%;
}
.s3-bg-color{
    height: 18px;
    width: 18px;
}

.column-style{
    padding-left: 2% ;
}
