@use "../commonStyles/styles" as globalStyles;
@use "../commonStyles/fonts" as globalFont;

.cloudqLogo {
  width: 35%;
  height: auto;
  position: relative;
  justify-content: center;
  display: flex;
  padding-bottom: 10%;
}

/* The main container */
.login-container {
  min-height: 100vh;
  background-color: globalStyles.$clr-white;

  /* Flex layout for smaller screens (up to 600px width) */
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

/* Left side image container */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  /* For smaller screens (up to 600px width) */
  @media (max-width: 600px) {
    display: none; /* Hide the left side image on smaller screens */
  }
}

/* Wrapper to make the image fill the space */
.image-wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Right side login form container */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  /* For smaller screens (up to 600px width) */
  @media (max-width: 600px) {
    padding: 20px 10px;
    height: 100vh; /* Make the form container take the full height on smaller screens */
  }
}

/* Styling for the paper-like appearance */
.form-paper {
  background-color: globalStyles.$clr-white;
  box-shadow: 0px 0px 8px globalStyles.$clr-button-common;
  padding: 20px;
}

/* Styling for the "Remember my preference" checkbox */
label {
  margin-left: 5px;
}

.content-center {
  display: flex;
  justify-content: center;
}
