@use "../commonStyles/styles" as globalStyles;
@use "../commonStyles/fonts" as globalFont;

// .navbar {
//   color: globalStyles.$clr-white;
// }
// .navbar-items li a {
//   font-size: 1.2rem;
//   padding: 10px;
// }
.logo {
  height: 35px;
  margin-right: 4rem;
  padding: 5% 0% 5% 0%;
}

// navbar-a a {
//   text-decoration: none;
//   color: globalStyles.$clr-white;
//   position: relative;
//   font-weight: normal;
//   font-family: globalFont.$primary-font;
// }

// navbar-a a:hover {
//   text-decoration: none;
//   font-weight: bold;
// }

// navbar-a a:hover::after {
//   content: "";
//   position: absolute;
//   left: 0;
//   bottom: -2px;
//   width: 100%;
//   height: 2px;
//   background-color: globalStyles.$clr-primary-2;
//   font-weight: bolder;
// }

// .navbar-list {
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
// }

// .avtar-align {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-right: 5%;
// }

.logout-icon {
  cursor: pointer;
}
