@use "../commonStyles/themes_1" as globalStyles;
@use "../commonStyles/fonts" as globalFont;

.menu-style {
  text-transform: none;
  font-family: globalFont.$ubuntu-font;
}

.menu-style:hover,
.menu-style:focus,
.menu-style.active {
  text-decoration: underline;
}
