@use "../commonStyles/styles" as globalStyles;

.sidebar {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sub-header {
  padding: 1% 0% 1% 1%;
  font-weight: bold;
}

.dialogHighlight {
  color: globalStyles.$clr-red;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0% 2% 0%;
}

.sub-header-addUser {
  padding: 0% 0% 1% 1%;
}
.sub-group-header {
  padding: 10% 0% 10% 1%;
}

.bgProps {
  // height: 100vh;
  background-color: globalStyles.$clr-background;
}

.bg-color {
  color: globalStyles.$clr-primary-1;
  width: 24px;
  height: 24px;
}

.pointerCursor {
  cursor: pointer;
}

.MuiDataGrid-iconButton .MuiIconButton-label .custom-header-menu-icon {
  color: white;
}
