// * Styles used here are for export purpose in various files

// * primaryColor1
$clr-primary-1: #065cbe;
$clr-primary-2: rgb(7, 96, 211);
// * buttonColorCommon
// $clr-button-common: #01234b;
$clr-button-common: #065cbe;
// *listSelectionColor
$clr-list-selection: #2988fc;

$clr-white: #fff;

$clr-red: red;

$clr-itemHover: #d9e5fc;

$clr-itemHover-1: #afc5dd;

$clr-black: #000;

$clr-purple: "purple";

$clr-bluebackground: #bfd5f0;

$clr-hoverBlueDark: #065cbe;

:export {
  primaryColor1: $clr-primary-1;
  buttonColorCommon: $clr-button-common;
  listSelectionColor: $clr-list-selection;
  whiteColor: $clr-white;
  redColor: $clr-red;
  itemHover: $clr-itemHover;
  blackColor: $clr-black;
  purpleColor: $clr-purple;
  backgroundBlue: $clr-bluebackground;
  hoverBlueDark: $clr-hoverBlueDark;
  itemHover1: $clr-itemHover-1;
}
