body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* customised scroll bar */
/* Chrome, Safari, and Opera */
/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-3);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333;
} */

/* Firefox */
::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-thumb {
  background-color: #555;
  border-radius: 5px;
}

::-moz-scrollbar-thumb:hover {
  background-color: #333;
}

/* Internet Explorer and Edge */
::-ms-scrollbar {
  width: 10px;
}

::-ms-scrollbar-thumb {
  background-color: #555;
  border-radius: 5px;
}

::-ms-scrollbar-thumb:hover {
  background-color: #333;
}

.MuiSvgIcon-fontSizeSmall {
  color: #bfd5f0 !important;
}
