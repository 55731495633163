// * Styles used here are for export purpose in various files

// * primaryColor1
$clr-primary-1: #065cbe;
$clr-primary-2: rgb(7, 96, 211);
// * buttonColorCommon
$clr-button-common: #065cbe;
// *listSelectionColor
$clr-list-selection: #2988fc;

$clr-white: #fff;

$clr-red: red;

$clr-background: #f0f0f0;

$clr-black: #000;

:export {
  primaryColor1: $clr-primary-1;
  buttonColorCommon: $clr-button-common;
  listSelectionColor: $clr-list-selection;
  whiteColor: $clr-white;
  redColor: $clr-red;
  blackColor: $clr-black;
}
